import React, { useState } from "react";

export default function Dashboard() {
    const [fechaIni, setFechaIni] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [fechaIniAbono, setFechaIniAbono] = useState('');
    const [fechaFinAbono, setFechaFinAbono] = useState('');
    const customWidth = {
        width: '20%',
        display: 'inline-block',
        paddingRight: '10px'
    };

    const getUrlExcel = () => {
        const currentUser = JSON.parse(localStorage.getItem('user_fact'));
        if (currentUser && currentUser.token) {
            return `${process.env.REACT_APP_API_BASE}movimientos/excel?token=${currentUser.token}`;
        }
    }

    const getUrlExcelFacturas = (fechaIni, fechaFin) => {
        const currentUser = JSON.parse(localStorage.getItem('user_fact'));
        if (currentUser && currentUser.token) {
            const tokenParam = `token=${currentUser.token}`;
            const fechaIniParam = fechaIni ? `fechaIni=${fechaIni}` : '';
            const fechaFinParam = fechaFin ? `fechaFin=${fechaFin}` : '';

            const queryParams = [tokenParam, fechaIniParam, fechaFinParam].filter(Boolean).join('&');

            return `${process.env.REACT_APP_API_BASE}facturas/excel?${queryParams}`;
        }
    };

    const getUrlExcelAbonos = (fechaIniAbono, fechaFinAbono) => {
        const currentUser = JSON.parse(localStorage.getItem('user_fact'));
        if (currentUser && currentUser.token) {
            const tokenParam = `token=${currentUser.token}`;
            const fechaIniParam = fechaIniAbono ? `fechaIni=${fechaIniAbono}` : '';
            const fechaFinParam = fechaFinAbono ? `fechaFin=${fechaFinAbono}` : '';

            const queryParams = [tokenParam, fechaIniParam, fechaFinParam].filter(Boolean).join('&');

            return `${process.env.REACT_APP_API_BASE}abonos/excel?${queryParams}`;
        }
    };

    const getUrlExcelFacturasAntesCierre = () => {
        const currentUser = JSON.parse(localStorage.getItem('user_fact'));
        if (currentUser && currentUser.token) {
            const tokenParam = `token=${currentUser.token}`;
            const queryParams = [tokenParam].filter(Boolean).join('&');
            return `${process.env.REACT_APP_API_BASE}facturas/excelCierre?${queryParams}`;
        }
    };

    const getUrlExcelFacturasFinMes = () => {
        const currentUser = JSON.parse(localStorage.getItem('user_fact'));
        if (currentUser && currentUser.token) {
            const tokenParam = `token=${currentUser.token}`;
            const queryParams = [tokenParam].filter(Boolean).join('&');
            return `${process.env.REACT_APP_API_BASE}facturas/excelPagarFinMes?${queryParams}`;
        }
    };

    const getUrlExcelAbonosFinMes = () => {
        const currentUser = JSON.parse(localStorage.getItem('user_fact'));
        if (currentUser && currentUser.token) {
            const tokenParam = `token=${currentUser.token}`;
            const queryParams = [tokenParam].filter(Boolean).join('&');
            return `${process.env.REACT_APP_API_BASE}abonos/excelPagarFinMes?${queryParams}`;
        }
    };

    const getUrlExcelAbonosAntesCierre = () => {
        const currentUser = JSON.parse(localStorage.getItem('user_fact'));
        if (currentUser && currentUser.token) {
            const tokenParam = `token=${currentUser.token}`;
            const queryParams = [tokenParam].filter(Boolean).join('&');
            return `${process.env.REACT_APP_API_BASE}abonos/excelCierre?${queryParams}`;
        }
    };

    const handleExportMovimientosClick = () => {
        const url = getUrlExcel();
        window.location.href = url;
    };

    const handleExportFacturasClick = () => {
        const url = getUrlExcelFacturas(fechaIni, fechaFin);
        window.location.href = url;
    };

    const handleExportAbonosClick = () => {
        const url = getUrlExcelAbonos(fechaIniAbono, fechaFinAbono);
        window.location.href = url;
    };

    const handleExportFacturasFinMesClick = () => {
        const url = getUrlExcelFacturasFinMes();
        window.location.href = url;
    };

    const handleExportAbonosFinMesClick = () => {
        const url = getUrlExcelAbonosFinMes();
        window.location.href = url;
    };

    const handleExportFacturasAntesCierreClick = () => {
        const url = getUrlExcelFacturasAntesCierre();
        window.location.href = url;
    };

    const handleExportAbonosAntesCierreClick = () => {
        const url = getUrlExcelAbonosAntesCierre();
        window.location.href = url;
    };

    return (
        <div className="content pt-3">
            <h1>Exportadores</h1>
            <p className="mb-2">Selecciona los filtros para generar una exportación</p>

            <fieldset className="export-fieldset">
                <legend>Exportar movimientos</legend>
                <div style={customWidth} className="flex flex-col flex-1">
                    <button
                        className="btn btn-success"
                        onClick={handleExportMovimientosClick}
                    >
                        Exportar a excel
                    </button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar facturas</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <div style={{ marginRight: '10px' }}>
                        <label>Fecha Inicial:</label>
                        <input
                            type="date"
                            value={fechaIni}
                            onChange={(e) => setFechaIni(e.target.value)}
                        />
                    </div>

                    <div style={{ marginRight: '10px' }}>
                        <label>Fecha Final:</label>
                        <input
                            type="date"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                        />
                    </div>

                    <button className="btn btn-primary" onClick={handleExportFacturasClick}>Exportar facturas</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar facturas para pagar a fin de mes</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <button className="btn btn-primary" onClick={handleExportFacturasFinMesClick}>Exportar facturas</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar facturas antes de cierre</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <button className="btn btn-primary" onClick={handleExportFacturasAntesCierreClick}>Exportar facturas</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar abonos</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <div style={{ marginRight: '10px' }}>
                        <label>Fecha Inicial:</label>
                        <input
                            type="date"
                            value={fechaIniAbono}
                            onChange={(e) => setFechaIniAbono(e.target.value)}
                        />
                    </div>

                    <div style={{ marginRight: '10px' }}>
                        <label>Fecha Final:</label>
                        <input
                            type="date"
                            value={fechaFinAbono}
                            onChange={(e) => setFechaFinAbono(e.target.value)}
                        />
                    </div>

                    <button className="btn btn-primary" onClick={handleExportAbonosClick}>Exportar abonos</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar abonos para pagar a fin de mes</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <button className="btn btn-primary" onClick={handleExportAbonosFinMesClick}>Exportar abonos</button>
                </div>
            </fieldset>
            <fieldset className="export-fieldset">
                <legend>Exportar abonos antes de cierre</legend>
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: '10px' }}>
                    <button className="btn btn-primary" onClick={handleExportAbonosAntesCierreClick}>Exportar abonos</button>
                </div>
            </fieldset>
        </div>
    );
}
